import { Link } from "gatsby"
import Seo from "../components/seo"
import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { ButtonGroup, ToggleButton, Container } from "react-bootstrap"
import Axios from "axios"

const Main = () => {
  const [rhost, setRhost] = useState([])

  useEffect(() => {
    const getData = async () => {
      await Axios.get(`https://api.puntohost.com/2/reseller-hosting.php`).then(
        response => {
          const result = response.data
          setRhost(result)
        }
      )
    }
    getData()
  }, [])

  const [currencyValue, setCurrencyValue] = useState("1")

  const currencies = [
    { name: "Dólares", value: "1" },
    { name: "Bolívares", value: "2" },
  ]

  return (
    <Layout>
      <Seo
        title="Reseller Hosting"
        description="Reseller Hosting cPanel/WHM potenciado con CloudLinux, certificados SSL Let's encrypt y DNS personalizados"
      />
      <div className="h-hosting">
        <div className="text-center pt-6 pb-6">
          <Container>
            <h1>Reseller Hosting, emprende con nosotros.</h1>
            <p>
              Nuestro alojamiento web para revendedores elimina el estrés de
              administrar varios sitios web.
            </p>
          </Container>

          <ButtonGroup
            data-toggle="buttons"
            className="btn-group-toggle btn-group"
            role="group"
            aria-label="Cambio de moneda"
          >
            {currencies.map((currency, idx) => (
              <ToggleButton
                key={idx}
                id={`currency-${idx}`}
                type="radio"
                variant="dark"
                name="currency"
                value={currency.value}
                checked={currencyValue === currency.value}
                onChange={e => setCurrencyValue(e.currentTarget.value)}
              >
                {currency.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <Container className="text-right cc"></Container>
      </div>

      <section id="pricing" className="container pb-4">
        {currencyValue === "1" ? (
          <>
            <div className="row">
              {rhost.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <span className="discount">En oferta: Ahorra 50%</span>
                      <br />
                      <span className="prefix">{data.usd.prefix}</span>
                      <span>{(data.usd.price.monthly / 2).toFixed(2)}</span>
                      <span className="mes">/mes</span>
                      <p className="renew text-muted">
                        {data.usd.prefix}
                        {data.usd.price.monthly / 1} /mes cuando renuevas
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.usd.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.usd.url}`}
                      >
                        Contratar
                      </Link>
                    </div>
                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="row">
              {rhost.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <span className="discount">En oferta: Ahorra 50%</span>
                      <br />
                      <span className="prefix">{data.ves.prefix}</span>
                      <span>{(data.ves.price.monthly / 2).toFixed(0)}</span>
                      <span className="mes">/mes</span>
                      <p className="renew text-muted">
                        {data.ves.prefix}
                        {data.ves.price.monthly / 1}/mes cuando renuevas
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.usd.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.usd.url}`}
                      >
                        Contratar
                      </Link>
                    </div>
                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>

      <div className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>La simplicidad se une al rendimiento.</h2>
            <span>Todas las mejores características al mejor precio.</span>
          </div>
        </div>
      </div>

      <section id="features" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-22 icon-2x"></i>
              <p className="f-title">Certificado SSL GRATIS</p>
              <p>
                Proporcionamos certificados SSL gratuitos "Let's Encrypt" para
                proteger todos su sitios con HTTPS.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-80 icon-2x"></i>
              <p className="f-title">Panel fácil de usar</p>
              <p>
                Reventa automática con cPanel y WebHost Manager, cree paquetes y
                aprovisione cuentas rapidamente.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-85 icon-2x"></i>
              <p className="f-title">Potente instalador de 1-click</p>
              <p>
                Ahorre tiempo y dinero, instale WordPress u otra aplicación
                preensamblada con un click.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-55 icon-2x"></i>
              <p className="f-title">Migración de sitio web</p>
              <p>
                ¿Su cliente está alojando en otro lugar ahora? No se preocupe,
                lo tenemos cubierto. Migramos a tus clientes de forma gratuita.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-71 icon-2x"></i>
              <p className="f-title">Tiempo de actividad del 99,9%</p>
              <p>
                Ofrecemos el nivel más alto de tiempo de actividad del servidor,
                por lo que puede estar seguro de que su sitio siempre está en
                línea.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-88 icon-2x"></i>
              <p className="f-title">DNS marca blanca</p>
              <p>
                Tus clientes - Tu marca, contarás con DNS marca blanca o
                personalizados sin cargos.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Main
